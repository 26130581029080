module.exports = {
	"email": "E-post",
	"phone": "Telefon",
	"name": "Namn",
	"avatar": "Profilbild",
	"save": "Spara",
	"saved": "Spara",
	"saving": "Sparar",
	"edit": "Redigera",
	"create": "Skapa",
	"remove": "Radera",
	"reinvite": "Bjud in på nytt",
	"createUser": "Skapa användare",
	"loading": "Laddar {resource}",
	"dashboard": "Dashboard",
	"project": "Projekt | Projekt",
	"theProject": "Projektet",
	"map": "Karta",
	"yourProject": "Ditt projekt | Dina projekt",
	"allProjects": "Alla projekt",
	"noProjects": "Ingen projekt",
	"residential": "Bostad| Bostäder",
	"noResidentials": "Inga bostäder",
	"residentialTemplate": "Bostadstyp | Bostadstyper",
	"createResidentialTemplate": "Skapa bostadstyp",
	"createUnitTemplate": "",
	"user": "Användare | Användare",
	"settings": "Inställningar",
	"login": "Logga in",
	"logout": "Logga ut",
	"termsCondition": "Avtalsvillkor",
	"privacyPolicy": "Persondataskydd",
	"cookiePolicy": "Cookiepolicy",
	"menu": "Meny",
	"followUs": "Följ oss",
	"getStarted": "Kom i gång",
	"noAccess": "Ingen åtkomst",
	"projectName": "Projektnamn",
	"publish": "Publicera",
	"publishing": "Publicering",
	"published": "Publicerat",
	"unpublished": "Inte publicerat",
	"domain": "Domän",
	"retry": "Försök igen",
	"close": "Stäng",
	"showDetails": "Visa detaljer",
	"hideDetails": "Dölj detaljer",
	"createLead": "Ny intressent",
	"newLead": "Ny intressent",
	"lead": "Intressent | Intressenter",
	"leadMessage": "Besked/Information",
	"message": "Meddelande | Meddelanden",
	"newMessage": "Nytt meddelande",
	"comment": "@:message",
	"noMessages": "Inga meddelande",
	"description": "Beskrivning",
	"showMore": "Visa fler",
	"noLeads": "Inga intressenter",
	"openhouse": "Visning | Visningar",
	"openhouseOnRequest": "Visning enligt avtal",
	"noOpenhouse": "Inga visningar",
	"createOpenhouse": "Ny visning",
	"from": "Från",
	"to": "Till",
	"date": "Datum",
	"role": "Roll | Roller",
	"flatfinder": "Bostadsväljare | Bostadsväljare",
	"unitSelector": "",
	"flatfinderTypeFlat": "Lägenhetsväljare | Lägenhetsväljare",
	"flatfinderTypePlot": "Tomtväljare | Tomtväljare",
	"flatfinderTypeOffice": "",
	"createFlatfinder": "Ny bostadsväljare",
	"createUnitSelector": "",
	"home": "Hem",
	"statistics": "Statistik",
	"notset": "Inte fastställt",
	"projectmanager": "Projektmedarbetare | Projektmedarbetare",
	"customermanager": "Kundtjänstmedarbetare | Kundtjänstmedarbetare",
	"complaintmanager": "",
	"search": "Sök",
	"searchFor": "Sök efter",
	"cancel": "Avbryt",
	"confirm": "Bekräfta",
	"couldnotfindonquery": "Ingen träff för ”{query}”",
	"selectx": "Välj {x}",
	"brochure": "Prospekt",
	"downloadBrochure": "Ladda ned prospekt",
	"logo": "Logo",
	"logoInverted": "Logo (ljus variant)",
	"favicon": "Favicon (Webbläsarikon)",
	"gallery": "Galleri",
	"internalId": "Intern-ID",
	"createProject": "Nytt projekt",
	"address": "Adress",
	"postcode": "Postnummer",
	"city": "Ort",
	"county": "Kommun",
	"latitude": "Latitud",
	"longitude": "Longitud",
	"position": "Position",
	"createResidential": "Ny bostad",
	"cadastre": "Fastighetsregister/Matrikel",
	"residentialnumber": "Fastighetsbeteckning",
	"number": "Nummer",
	"street": "Gatuadress",
	"floorplan": "Planskiss | Planskisser",
	"dragImageOrDrop": "Dra bilden hit eller <b>klicka</b> för att ladda upp | Dra bilder hit eller <b>klicka</b> för att ladda upp",
	"dragFilesOrDrop": "Dra filer {extension} hit eller <b>klicka</b> för att ladda upp",
	"dragFileOrDrop": "Dra fil {extension} hit eller <b>klicka</b> för att ladda upp",
	"price": "Pris",
	"residentialProperties": "Nyckelinfo",
	"propertyType": "Bostadstyp",
	"propertyTypeWarning": "<b>OBS!</b> Du har valt bostadstyp ”{value}” och måste därför lägga in värden per enhet/objekt.",
	"numberOfBedrooms": "Sovrum",
	"numberOfRooms": "Rum",
	"numberOfBathrooms": "Bad/WC",
	"primaryRoomArea": "Boyta/Boarea",
	"grossArea": "Bruttoarea",
	"useableArea": "Bruksarea",
	"floor": "Våning",
	"numberOfFloors": "Antal våningar",
	"plotArea": "Tomtareal",
	"ownershipType": "Ägarform",
	"numberOfParkingSpots": "Antal parkeringsplatser",
	"energyLabel": "Energimärkning",
	"plotOwned": "Egen tomt",
	"plotGroundTax": "Fastighetsskatt",
	"plotOwnershipType": "Ägarförhållande (tomt)",
	"communityTax": "Kommunala avgifter",
	"taxValue": "Fastighetsvärde",
	"constructionYear": "Byggår",
	"renovatedYear": "Renoverat år",
	"value": "Värde",
	"add": "Lägg till",
	"custom": "Egendefinierat",
	"yes": "Ja",
	"no": "Nej",
	"quantity": "Antal",
	"none": "Ingen/Inget",
	"bid": "Bud",
	"addBid": "Nytt bud",
	"bidUrl": "Länk för budgivning",
	"purchaseUrl": "Länk för signering av köpekontrakt",
	"size": "Storlek",
	"sale": "Försäljning",
	"basis": "Underlag",
	"presentation": "Presentation",
	"integration": "Integration | Integrationer",
	"next": "Nästa",
	"prev": "Föregående",
	"cover": "Cover",
	"propertyType:detached": "Enfamiljshus | Enfamiljshus",
	"propertyType:flat": "Lägenhet | Lägenheter",
	"propertyType:terraced": "Radhus",
	"propertyType:semidetached": "Parhus",
	"propertyType:plot": "Bostadstomt | Bostadstomter",
	"propertyType:leisure": "Stuga | Stugor",
	"propertyType:leisurePlot": "Fritidstomt | Fritidstomter",
	"propertyType:office": "",
	"openhouseReferences": "Var är det visning?",
	"isInterestedIn": "Är intresserad av",
	"leadReferences": "@:isInterestedIn",
	"theWholeProject": "Hela projektet",
	"getInTouch": "Kontakta oss",
	"leadFormTitle": "Intresseanmälan",
	"leadConfirm": "Tack för ditt intresse",
	"residentialStatus": {
		"draft": "@:unpublished",
		"upcoming": "Kommande försäljning",
		"sale": "Till salu",
		"sold": "Sålt",
		"reserved": "Reserverat"
	},
	"residentialStatus:sale": {
		"draft": "@:unpublished",
		"upcoming": "Kommande försäljning",
		"sale": "Till salu",
		"sold": "Sålt",
		"reserved": "Reserverat"
	},
	"residentialStatus:rent": {
		"draft": "@:unpublished",
		"upcoming": "Kommande försäljning",
		"reserved": "Reserverat",
		"rent": "",
		"rented": ""
	},
	"viewResidentials": "Se bostäder",
	"status": "Status",
	"bidAction": "Köp",
	"residentialSaleFee": "Omkostnader",
	"residentialSaleFixed": "Fastpris",
	"residentialSaleFeeIncluded": "Angivet pris är inklusive omkostnader",
	"contact": "Kontakt | Kontakter",
	"editContact": "Ändra kontakt",
	"createContact": "Skapa kontakt",
	"contactUpsell": "Jag vill ha information om liknande projekt",
	"leadPrivacy": "Jag vill bli kontaktad i enlighet med {0}",
	"contractPrivacy": "Jag är införstådd med {0}",
	"salesmanVisibility": "Kundtjänstmedarbetarnas kontaktinformation för detta projekt kommer att visas på projektsidan",
	"areYouSure": "Är du säker?",
	"somethingWentWrong": "Ett fel inträffade",
	"completed": "Avslutat/Genomfört",
	"complete": "Avsluta/Genomför",
	"completing": "Avslutar/Genomför",
	"youAreAboutToRemove": "Du håller på att radera",
	"removeRelationsTitle": "Du raderar även tillhörande:",
	"showAll": "Visa alla",
	"attachment": "Bilaga | Bilagor",
	"preview": "Förhandsvisa",
	"transferToUser": "Flytta anslutna datum till:",
	"upcomingDeadlines": "Kommande tidsfrister",
	"noDeadlines": "Inga kommande tidsfrister",
	"recentActivity": "Senaste händelser",
	"noActivity": "Inga händelser",
	"task": "Uppdrag | Uppdrag",
	"noTasks": "Inga uppdrag",
	"new": "Ny",
	"showCoordinates": "Visa koordinater",
	"readOurTerms": "Läs igenom avtalsvillkoren",
	"iAcceptTerms": "Jag har läst igenom och godkänner avtalsvillkoren",
	"sendAndAccept": "Fortsätt",
	"privacy": "Dataskyddsförordningen",
	"notfoundTitle": "Sidan finns inte",
	"projectWillPublish": "Publiceras snart",
	"projectWillPublishAt": "Publiceras den {date}",
	"projectUnpublished": "Projektet är avslutat",
	"pageMadeBy": "Sidan är skapad i",
	"processedBy": "Behandlat av",
	"markAsProcessed": "Ange som behandlat",
	"openhouseAtValue": "Visning på {value}",
	"noMoreResidentials": "Inga fler bostäder",
	"welcomeName": "Välkommen, {name}",
	"youHaveBeenAddedToCompany": "Du är registrerad som användare hos {company}.",
	"activateAccount": "Aktivera konto",
	"pressButtonToActivateAccount": "Klicka på knappen för att välja ditt lösenord och aktivera kontot.",
	"orgNo": "Organisationsnummer",
	"howToGetLatLng": "För att placera ut projektet på kartan, rekommenderar vi dig att använda {0} för att få korrekta koordinater. Adressuppslag innehåller som oftast inga nya adresser och du är därför tvungen att ta fram detta manuellt. Dessutom kommer positionen att bli mer exakt.",
	"image": "Bild | Bilder",
	"flatfinderBuilder": "Upplägg",
	"leaflet-v1": {
		"drawToolbar": {
			"actions": {
				"title": "Avbryt skiss",
				"text": "Avbryt"
			},
			"finish": {
				"title": "Avsluta/Genomför ritning",
				"text": "Avsluta/Genomför"
			},
			"undo": {
				"title": "Radera den senast ritade punkten",
				"text": "Radera den senaste punkten"
			},
			"buttons": {
				"polygon": "Rita en markering"
			}
		},
		"drawHandlers": {
			"polygon": {
				"error": "Fel!",
				"tooltip": {
					"start": "Klicka för att rita en markering.",
					"cont": "Klicka för att fortsätta rita en markering.",
					"end": "Tryck på den första punkten för att stänga denna markering."
				}
			}
		},
		"editToolbar": {
			"actions": {
				"save": {
					"title": "Spara ändringar.",
					"text": "Spara"
				},
				"cancel": {
					"title": "Avbryt redigering, avvisa alla ändringar.",
					"text": "Avbryt"
				},
				"clearAll": {
					"title": "Radera alla markeringar.",
					"text": "Radera alla"
				}
			},
			"buttons": {
				"edit": "Redigera markeringar.",
				"editDisabled": "Inga markeringar att redigera.",
				"remove": "Radera markeringar.",
				"removeDisabled": "Inga markeringar att radera."
			}
		},
		"editHandlers": {
			"edit": {
				"tooltip": {
					"text": "Dra punkterna eller markera för att redigera.",
					"subtext": "Klicka på avbryt för att annullera ändringar."
				}
			},
			"remove": {
				"tooltip": {
					"text": "Tryck på en markering för att radera."
				}
			}
		}
	},
	"theme": "Tema",
	"selectTheme": "Välj tema",
	"show": "Visa",
	"activateMarketingButton": "Köp extra synbarhet",
	"couldNotLoadContent": "Kunde inte ladda innehåll",
	"couldNotLoadContentMessage": "Försök ladda sidan på nytt. Om problemet kvarstår, vänligen kontakta {0}.",
	"personalInformation": "Personliga upplysningar",
	"bidInformation": "Budinformation",
	"bidAuthentication": "Legitimation",
	"bidSigning": "Signering",
	"bidConfirmation": "Bud signerat",
	"purchaseSigning": "Signering",
	"purchaseConfirmation": "Köpekontrakt signerat",
	"socialSecurityNumber": "Personnummer",
	"bidApplicant": "Köpare",
	"bidHasCoApplicant": "Lägg till medköpare",
	"purchaseHasCoApplicant": "@:bidHasCoApplicant",
	"bidCoApplicant": "Medköpare",
	"amount": "Belopp",
	"bidExpiresAt": "Acceptfrist",
	"bidTakeoverAt": "Önskad överlåtelse",
	"bidCondition": "Eventuella förhållanden",
	"passwordEnforcementMessage": "Lösenordet måste innehålla minst 8 tecken, stora/små bokstäver samt minst 1 siffra",
	"bank": "Bank",
	"financeContact": "Kontaktperson",
	"financingPlan": "Finansieringsplan",
	"signingAuthority": "Fullmaktsinnehavare för signering",
	"bidContract": "Köpbekräftelse",
	"purchaseContract": "Köpekontrakt",
	"shopContract": "Tillvalsavtal",
	"shop-partialContract": "Tillvalsavtal ─ Delsignering",
	"bidSignedMessage": "Ditt bud har signerats och skickats. Du kommer inom kort att få en bekräftelse via e-post. Så snart ditt bud har behandlats, kommer du att få svar via e-post.",
	"purchaseSignedMessage": "Ditt köpekontrakt har signerats och skickats. Du kommer inom kort att få en bekräftelse via e-post. Så snart ditt köpekontrakt har behandlats, kommer du att få svar via e-post.",
	"gotoProject": "Gå till projektet",
	"parent": "Föräldrar",
	"goBack": "Gå tillbaka",
	"removeDemoContent": "Radera demo-innehåll",
	"unsavedChangesDialog": "Du har icke-sparade ändringar som går förlorade om du fortsätter. Är du säker på att du vill fortsätta?",
	"confirmation": "Bekräftelse",
	"yourDocumentsAreAttached": "Bifogat hittar du alla dokument",
	"accept": "Godkänn",
	"decline": "Avslå",
	"document": "Dokument | Dokument",
	"noDocuments": "Inga dokument",
	"projectsite": "Projektwebbsida",
	"signer": "Undertecknande | Undertecknande",
	"youDoNotHaveSignAuthority": "Endast fullmaktsinnehavare för signering kan godkänna/avslå detta dokument",
	"update": "Uppdaterar",
	"fieldsToSync": "Data som skall överskrivas",
	"selectAll": "Välj alla",
	"residentialTemplateSyncReferences": "Uppdatera relaterade bostäder",
	"askResidentialTemplateSyncReferences": "Vill du uppdatera anslutna bostäder?",
	"projectDomainSetup": "För att ändringen skall bli synlig på internet, måste du installera en DNS-pekare för din domän (se nedan). Kom ihåg att det kan ta lite tid innan ändringen är synbar på webben. Vid frågor, kontakta oss via vår kundchatt.",
	"lookingForAUserAdd": "Hittar du inte användaren du letar efter?",
	"select": "Välj",
	"syncReferencesWarning": "Var noggrann när du väljer vilka data som skall överskrivas. Alla valda fält kommer att överskrivas med det nya innehållet.",
	"themeDoesNotSupportColor": "Det valda temat stödjer inte möjligheten för temafärg.",
	"themeColor": "Temafärg",
	"youAreHere": "Du är här",
	"resetSelection": "Återställ val",
	"selection": "Val",
	"thisIsADemo": "Detta är en demo",
	"userIsGuest": "Gäst / Har inte tillgång till systemet",
	"guest": "Gäst",
	"inviteSent": "Inbjudan skickad",
	"demo": "Demo",
	"thisMessageHasBeenSentTo": "Denna information har skickats till",
	"tourSeoAnalyticsTagManager": "SEO / Analytics / Tag Manager",
	"tourDeveloperTools": "Användning av utvecklarverktyg",
	"tourBusinessSite": "Så här ändrar du företagssida",
	"tourRecommendedImageFormat": "Rekommenderade bildformat",
	"tourHowToAddUser": "Så här lägger till en användare",
	"tourHowToCreateProjects": "Så här skapar du projekt",
	"tourSingleResidentialSale": "Enmanshus-försäljning",
	"tourHowToAddLeads": "Så här lägger du till intressenter",
	"tourHowToCreateResidentials": "Så här skapar du bostäder",
	"addonPresentation": "Presentation",
	"addonBid": "Budgivning",
	"addonPurchase": "Köpekontrakt",
	"addonShop": "@:shop",
	"addonShopPurchase": "Tillvalsbutik ─ Signering",
	"tourAboutProjectDashboard": "Om projekt-dashboard",
	"tourHowToEditResidentials": "Så här redigerar du bostäder",
	"tourHowToDeleteResidentials": "Så här raderar du bostäder",
	"tourTitleOnScreen": "Hjälpguider (Interaktiv)",
	"tourTitleCourseVideos": "Utbildningsvideor",
	"tourTitleManuals": "Hjälpguider (Manual)",
	"flatfinderIsEntry": "Huvud-bostadsväljare (visa denna först på projektsidan)",
	"flatfinderCreateResidentialWarning": "När du lägger upp en bostadsväljare, så skall du ansluta bostäder. Det är därför viktigt att du först skapa bostäderna innan du lägger upp bostadsväljaren.",
	"flatfinderCreateResidentialWarningTitle": "Vi ser att du inte har skapat några bostäder",
	"flatfinderCreateResidentialWarningException": "Om du inte skall lägga upp bostäder nu, kan du bortse från detta meddelande och fortsätta.",
	"continue": "Fortsätt",
	"default": "Standard",
	"richTextUpdateLink": "Uppdatera länk",
	"richTextAddLink": "Lägg till länk",
	"multiplicatorMonthly": "mån",
	"multiplicatorProject": "projekt",
	"contractStatusAccepted": "Godkänt",
	"contractStatusDeclined": "Inte godkänt",
	"contractStatusDraft": "Utkast",
	"contractStatusSigned": "Ett dokument väntar på godkännande",
	"contractStatusUnsigned": "Du har ett dokument för signering",
	"bidErrorStatus": "Bostaden är inte till salu",
	"bidErrorAddon": "Modul för budgivning har inte aktiverats för detta projekt",
	"purchaseErrorStatus": "Bostaden är inte såld",
	"purchaseErrorAddon": "Modul för köpekontraktet har inte aktiverats för detta projekt",
	"signed": "Signerat",
	"gotoSigning": "Gå till signering",
	"sign": "Signera",
	"username": "Användarnamn",
	"accountUrl": "Länk till konto",
	"password": "Lösenord",
	"otherBidAcceptedMessage": "Ett annat bud har accepterats",
	"bidConfirmationMessage": "Vi har mottagit ditt bud och du kommer att få svar från oss så snart som möjligt",
	"bidAcceptMessage": "Ditt bud har godkänts",
	"bidDeclinedMessage": "Ditt bud har inte godkänts",
	"purchaseConfirmationMessage": "Vi har mottagit ditt köpekontrakt och du kommer att få svar från oss så snart som möjligt",
	"purchaseAcceptMessage": "Ditt köpekontrakt har godkänts",
	"purchaseDeclinedMessage": "Ditt köpekontrakt har inte godkänts",
	"shopConfirmationMessage": "Vi har mottagit din signerade tillvalsbeställning. När motparten har signerat denna kommer du att få en bekräftelse. Bifogat hittar du en översikt över din beställning.",
	"shopAcceptMessage": "Ditt tillvalskontrakt har godkänts",
	"shopDeclinedMessage": "Ditt tillvalskontrakt har inte godkänts",
	"contractExpiredMessage": "Tidsfrist har löpt ut",
	"reason": "Motivering",
	"pressHereToCancelBid": "Vill du annullera köpet?",
	"youAreUsingBidAddon": "Du använder Kvass ─ Köpmodul",
	"pressHere": "Klicka här",
	"void": "Annullera",
	"reasonWillBeSentToApplicants": "Detta meddelande kommer att vidarebefordras till kund",
	"howToBuildContract": "För att dokumentet skall bli korrekt ifyllt, behöver du lägga upp en ifyllbar PDF-fil. Klicka på hjälp-knappen nedan, för att se hur du går tillväga.",
	"contractType": "Typ",
	"createDocument": "Skapa dokument",
	"upload": "Ladda upp",
	"bidUploadInfo": "Efter att du har laddat upp den signerade köpbekräftelsen kommer bostaden att markeras som såld",
	"onSoldStatusDialog": "För att markera denna bostad som såld, måste du ladda upp den signerade köpbekräftelsen.",
	"excludingVat": "exkl moms",
	"includingVat": "inkl moms",
	"priceOnRequest": "Pris efter förfrågan",
	"priceFrom": "Pris från",
	"pricePerMonth": "Pris per månad",
	"salePrice": "Försäljningspris",
	"priceMargin": "Tilläggsavgift",
	"purchasePrice": "Inköpspris",
	"readMore": "Läs mer",
	"upsaleRequestTemplate": "Jag vill ha hjälp med ”{0}”",
	"publishedFrom": "Publicerat från",
	"publishedTo": "Publicerat till",
	"customerService": "Kundtjänst",
	"residentialTemplateExplanation": "Genom att skapa bostadstyper slipper du att uppdatera samma underlag flera gånger. Du kan enkelt och snabbt uppdatera alla bostäder/ lägenheter som baserar sig på denna bostadstyp, samtidigt som du behåller möjligheten att ändra i bostäderna/ lägenheterna individuellt på projektnivå.",
	"unitTemplateExplanation": "",
	"product": "Produkt | Produkter",
	"createProduct": "Skapa produkt",
	"allProducts": "Alla produkter",
	"category": "Kategori | Kategorier",
	"productCategorySample": "exempelvis Golv, Parkett, VVS",
	"more": "Mer",
	"residentialStatusBidLock": "Du har aktiverat ”Köpmodul” och kan inte ändra status till/från ”såld” manuellt. Gå in på bostad för att ändra status.",
	"noAnalyticsCollected": "Vi har inte samlat in tillräckligt med data ännu. Du kommer att se statistik här så snart någon besöker webbsidan.",
	"sendEmail": "Skicka e-post",
	"customThemeActiveMessage": "Du använder en skräddarsydd mall för detta projekt.",
	"validDnsSetupMessage": "Domänen har inte lagts upp på ett korrekt sätt ─ kontrollera instruktionerna nedan och försök igen.",
	"siteSettings": "Sida inställningar",
	"showOpenhouseOnRequestIfNone": "Visa ”@:openhouseOnRequest” om inga visningar har planerats",
	"showLeadUpsell": "Visa ”@:contactUpsell” på intressent-formuläret",
	"showShopOnResidentialPage": "Visa tillvalsbutik på bostad-sidorna",
	"showShop": "Visa tillvalsbutik",
	"typeHere": "Skriv här",
	"shopCategoryExamples": "exempelvis Kök, Bad",
	"shopSubcategoryExamples": "exempelvis Kakel, Inredning",
	"shopAddProduct": "Välj produkt",
	"shopAddCategory": "@:addCategory",
	"shopAddSubcategory": "Lägg till underkategori",
	"lookingForAProductAdd": "Hittar du inte produkten du letar efter?",
	"hideSold": "Dölj sålda",
	"assetManagerTitleBrochureDirect": "Ladda ned prospekt",
	"assetManagerTitleBrochureLead": "Ladda ned prospekt",
	"assetManagerTitleBrochureRequest": "Få prospektet hemskickat",
	"assetManagerActionBrochureLead": "Ladda ned prospekt",
	"assetManagerActionBrochureRequest": "Skicka",
	"assetManagerFeedbackBrochureRequest": "Vi skickar prospektet till dig inom kort tid.",
	"assetManagerCommentBrochureRequest": "Jag vill få prospektet skickat till mig.",
	"brochureDownloadStrategyLabel": "Välj hur prospektet kan laddas ned",
	"brochureDownloadStrategyDirect": "Ladda ned direkt",
	"brochureDownloadStrategyLead": "Anmäl intresse först",
	"brochureDownloadStrategyRequest": "Skicka förfrågan / Skicka manuellt",
	"selected": "Valt",
	"shopDefaultProduct": "Standardleverans",
	"shopProductUpgrade": "Uppgraderingsmöjligheter",
	"totalPrice": "Totalpris",
	"shopContactSellerForOtherInquiries": "Vid övriga önskemål, kontakta säljare",
	"shopNotAvailableSize": "Öppna denna sida på en större skärm för optimal användarupplevelse av tillvalsbutiken",
	"gotoItem": "Gå till {0}",
	"activate": "Aktivera",
	"whatDoYouWantToCopy": "Vad skall kopieras?",
	"helpTools": "Manualer / Guider",
	"needHelp": "Tränger du hjälp?",
	"title": "Titel",
	"nearbyarea": "Området",
	"shopSync": "Kopiera",
	"shopSyncAll": "Hela tillvalsbutiken",
	"badge-bid": "@:bid",
	"badge-comment": "@:comment",
	"badge-openhouse": "@:openhouse",
	"badge-shopcontract": "@:addonShop",
	"badge-lead": "@:lead",
	"badge-purchase": "@:addonPurchase",
	"badge-task": "Uppdrag",
	"badge-customcontract": "",
	"prepareContract": "Förtydligande av kontrakt",
	"prepareShop": "Förtydligande av tillval",
	"preparePurchaseContract": "Förtydligande av köpekontrakt",
	"buyer": "Köpare | Köpare",
	"sendToSigning": "Skicka till signering",
	"deadline": "Tidsfrist",
	"shopLink": "Länk till tillvalsbutik (presentation)",
	"shopLinkWithSelection": "Länk till tillvalsbutik (val & signering)",
	"shopSigning": "Signering",
	"shopConfirmation": "Bekräftelse",
	"shopIntro": "Inledning",
	"shopSummary": "Uppsummering",
	"shopSignedMessage": "Ditt tillvalskontrakt har signerats och skickats. Du kommer inom kort att få en bekräftelse via e-post. Så snart ditt tillvalskontrakt har behandlats kommer du att få svar via e-post.",
	"shopSigningNotReady": "Det är inte öppet för signering ännu och du kommer att få besked så snart du kan signera",
	"startShopContract": "Starta tillvalsprocess",
	"shopReadyTitle": "Din tillvalsbutik är klar",
	"shopReadyMessage": "Du kan nu se och välja vilka tillval du vill ha för din bostad. \nDet är inte öppet för signering ännu, men du kan redan nu göra dina första val. Så snart det är öppet för signering kommer du att få ett meddelande.",
	"shopWelcomeMessage": "Välkommen till din tillvalsbutik ─ här kan du se och välja vilka tillval du vill ha för din bostad.\n\nSå snart du har gjort dina val kan du går vidare till uppsummeringen där du kommer att få möjlighet att signera din beställning.",
	"shopUnsignedMessage": "Du kan nu välja och signera dina tillval",
	"gotoShop": "Gå till tillvalsbutiken",
	"shopContractStatusReady": "Klar (skickad till köpare)",
	"shopContractStatusDraft": "Utkast",
	"shopContractStatusUnsigned": "Väntar på signatur",
	"shopContractStatusSigned": "@:signed",
	"shopContractStatusExpired": "Tidsfristen har löpt ut",
	"shopContractNotSold": "Denna bostad är inte såld ännu. Så snart bostadens status ändras till såld kommer du att kunna initiera signeringsprocessen.",
	"shopContractAttachment": "Bilaga (signeras av köpare)",
	"shopContractStartRequirements": "Innan du kan starta tillvalsprocessen måste du lägga in köpare. \nKom ihåg att spara ändringarna. ",
	"shopContractRememberSignableFrom": "Kom ihåg att markera ”@:signableFrom” så att köparen kan signera tillvalsbeställningen.",
	"file": "Fil | Filer",
	"import": "Importera",
	"productImport": "Produkt import",
	"importedXItem": "Importerade {count} {item}",
	"importFailedResolveIssues": "Importen kunde inte genomföras. Nedan hittar du en översikt över vad som blev fel. Vänligen korrigera detta och försök på nytt.",
	"pressButtonBelowToGetStarted": "Klicka på knappen nedan för att komma igång",
	"signingDeadlineIs": "Tidsfristen för signering är",
	"shopLinkPresentation": "Presentation",
	"shopLinkSigning": "Val / Signering (för köpare)",
	"export": "Exporterar",
	"page": "Sida | Sidor",
	"tourGetStartedSingleShop": "Så här kommer du igång med: Enkel modul ─ Tillval",
	"tourHowToAddPartDeadlinesShop": "Så här anger du delfrister för tillvalssignering",
	"tourHowIfOrIfNotShopWork": "Så här fungerar det om/om inte processen för tillval",
	"tourGetStartedShop": "Så här kommer du igång med: Tillvalsbutik",
	"tourGetStartedShopContract": "Så här kommer du igång med: Digital tillvalssignering",
	"tourTaskManagement": "Så här fungerar uppdrag-styrning i kunduppföljningssystemet",
	"tourGetStartedPurchaseContract": "Så här kommer du igång med: Digitalt köpekontrakt",
	"tourGetStartedBidContract": "Så här kommer du igång med: Digital köpmodul",
	"tourGetStartedSingleBidContract": "Så här kommer du igång med: Enkel modul ─ Digital köpmodul",
	"tourHowToEditProjectResidentials": "Så här redigerar du projektet/bostäder",
	"tourHowToCreateFlatfinder": "Så här skapar/redigerar du bostadsväljare",
	"tourHowToCreateResidentialTemplate": "Så här skapar/redigerar du bostadstyper",
	"tourHowToBuildContract": "Så här skapar du köpbekräftelse",
	"tourHowDashboardWorks": "Så här fungerar dashboard",
	"tourHowToCreateProjectTeaser": "Så här skapar du en teaser (Kommande försäljning)",
	"tourRoles": "Så här administrerar du användare",
	"tourHowToPublish": "Så här publicerar du projektet under en domän",
	"tourTitleAdditionalServices": "Vill du ha assistans?",
	"tourHowToBuildPDF": "Så här skapar du en ifyllbar PDF-fil",
	"tourHowToHandleLead": "Så här hanterar du intressenter",
	"tourHowToHandleOpenhouse": "Så här hanterar du visningar",
	"howManyUnitsOnProject": "Hur många enheter/objekt skall säljas totalt?",
	"attachmentIsEncryptedWithSSN": "Notera att bilagan är krypterad ─ lösenordet är ditt personnummer. Dokumentet du har fått är en kopia och originalet finns hos säljaren.",
	"contractApprovalWarning": "Kom ihåg att kontrollera dokumentets innehåll för godkännande.",
	"addon:project": "Projekt",
	"addon:project:presentation": "Projekt-/Bostad-sida",
	"addon:project:bid": "Köpmodul",
	"addon:project:purchase": "Köpekontrakt",
	"addon:project:shop": "Tillval ─ Presentation",
	"addon:project:shop-purchase": "Tilvalg ─ Försäljning",
	"addon:custom-contract": "",
	"addon:api": "",
	"addon:flatfinder": "",
	"addon": "Modul | Moduler",
	"fixedPrice": "Fastpris",
	"areaPrice": "Pris per m²",
	"customerPrice": "Pris till kund",
	"establishment": "Etablering",
	"ownershipType:leasehold": "Obligation",
	"ownershipType:freehold": "Ägare (självägande)",
	"ownershipType:other": "Annat",
	"ownershipType:partOwnership": "Andel",
	"ownershipType:collectiveOwnership": "Aktie",
	"plotOwnershipType:leasehold": "Obligation",
	"plotOwnershipType:freehold": "Ägare (självägande)",
	"plotOwnershipType:other": "Annat",
	"plotOwnershipType:partOwnership": "Andel",
	"plotOwnershipType:collectiveOwnership": "Aktie",
	"subpage": "Undersida | Undersidor",
	"orderItem": "Beställ {item}",
	"ShopPurchaseRequiresShop": "För att kunna ta i bruk ”Tillval ─ Försäljning”, behöver du även ”Tillval ─ Presentation”",
	"projectUnitLimitReached": "Du kan skapa ytterligare {0} enheter/objekt för detta projekt. För att lägga till fler enheter/objekt, behöver du uppgradera till en större licens. För uppgradering, vänligen kontakta oss via e-post: support@kvass.no eller via vår kundchatt.",
	"slug": "Slug",
	"content": "Innehåll",
	"setArea": "Ange area/yta",
	"rounding:none": "Ingen/Inget",
	"priceRounding": "Prisavrundning",
	"exampleShort": "exempelvis",
	"productOverrideMessage": "”{value}” är kopplad till en produktkatalog. Klicka på ”Redigera” för att ange ett eget värde.",
	"productOverrideReset": "Koppla samman ”{value}” med produktkatalog ─ ta bort eget värde.",
	"priceMissingArea": "Area/Yta saknas",
	"total": "Totalt",
	"instagramProfile": "Instagram-profil",
	"numberOfPosts": "Antal inlägg",
	"followUsOn": "Följ oss på {value}",
	"customFieldsResidentialTemplatesLabel": "Presentation av bostadstyper",
	"signableFrom": "Kan signeras från",
	"setPartialDeadlines": "Ange delfrister",
	"removePartialDeadlines": "Ta bort delfrister",
	"partialDeadline": "Delfrist | Delfrister",
	"madeBy": "En tjänst från",
	"gotoSummary": "Gå till uppsummering",
	"selectCategoriesToSign": "Välj kategorier som skall signeras",
	"shopBuyerStatusDraft": "Inte öppet för signering ännu",
	"shopBuyerStatusUnsigned": "Klar för signering",
	"wholeShop": "Hela tillvalsbutiken",
	"company": "Företag",
	"companyName": "Företagsnamn",
	"businessSite": "Företagssida",
	"private": "Privat",
	"footer": "Sidfotstext",
	"creatingContracts": "Skapar dokument för signering, vänligen vänta ett ögonblick.",
	"pressButtonBelowToGoToShop": "Klicka på knappen för att gå till din tillvalsbutik",
	"youHaveNewShopInfo": "Du har mottagit uppdaterad information för din tillvalsbutik",
	"youHaveAnUpcomingDeadline": "Du har en tidsfrist som snart löper ut",
	"hiName": "Hej, {name}",
	"shopPresentationSidebarDescriptionTitle": "Lägg upp din bostad",
	"shopPresentationSidebarDescriptionContent": "Här kan du se vilka uppgraderingar som finns tillgängliga för denna bostad.",
	"signingLoadingMessage": "Vi färdigställer dina dokument ─ vänligen vänta ett ögonblick (detta tar cirka 15─30 sek.)",
	"tag": "Märkningsetikett/Tagg | Märkningetiketter/Taggar",
	"noTags": "Inga märkningslappar",
	"youHaveANewMessageFrom": "Du har fått ett nytt meddelande från {from}",
	"nameSays": "{name} säger",
	"gotoDialog": "Gå till dialogen",
	"youCanAswerThisEmail": "Du kan svara på detta e-postmeddelande ─ ditt svar kommer att vidarebefordras",
	"send": "Skicka",
	"note": "",
	"thisMessageWasSentWith": "Detta meddelande har skickats med",
	"addCategory": "Lägg till kategori",
	"availableIf": "Tillgänglig om",
	"availableIfNot": "Inte tillgänglig om",
	"xActiveConditions": "Inga aktiva regler | {count} aktiv regel | {count} aktiva regler",
	"setConditions": "Ange regler",
	"displayName": "Visningsnamn",
	"useDisplayName": "Använd ett annat visningsnamn",
	"statisticsVisitors": "Besökstal",
	"statisticsSalesResidentials": "Försäljningstal",
	"statisticsSalesShop": "Försäljningstal (Tillval)",
	"externalUserAlert": "Du försöker lägga till en användare som inte ingår i din organisation ({domain}). \nVederbörande kommer att få åtkomst till projekten och tillhörande data i produktlösningen.\n\nVänligen bekräfta att du vill lägga till {email}.",
	"sendMessageToSeller": "Skicka meddelande till säljare",
	"sendMessageToSellerButtonLabel": "Funderar du över något? – Kontakta oss här",
	"sent": "Skickat",
	"member": "Medlem | Medlemmar",
	"editTask": "Redigera uppdrag",
	"createTask": "Skapa uppdrag",
	"undone": "Inte startat",
	"noDescription": "Ingen beskrivning",
	"noDeadline": "Ingen frist",
	"youHaveANewTask": "Du har fått ett nytt uppdrag",
	"gotoTask": "Gå till uppdraget",
	"youHaveX": "",
	"gotoDashboard": "Gå till dashboard",
	"youHaveXTaskDueIn": "Du har {count} uppdrag med frist som löper ut {dueby} | Du har {count} uppdrag med frist som löper ut {dueby}",
	"projectAdditionalFieldsDescriptionFooter": "Lägg till sidfotstext, exempelvis: Om oss/Åtkomst",
	"projectAdditionalFieldsDescriptionResidentailTemplate": "Välj vilka av dina bostadstyper du vill presentera på projektsidan.",
	"projectAdditionalFieldsDescriptionArea": "Lägg in texter och bilder för att presentera området eller liknande.",
	"projectAdditionalFieldsDescriptionSubpage": "Skapa undersidor där du kan presentera bilder och text. Du kan länka till undersidor genom att lägga till länkar i textfälten.",
	"clone": "Duplicera",
	"type": "Typ | Typer",
	"filter": "Filtrera",
	"noShopDefaultProduct": "Ingen standardleverans",
	"customer": "Kund | Kunder",
	"purchaseManuallyConfirmed": "Uppladdat köpekontrakt är signerat",
	"cannotAddMoreUsers": "Du kan inte lägga till flera användare. Vänligen kontakta kundtjänst om du vill lägga till fler.",
	"videoPreviewMessage": "Lägg till {type} länk för förhandsvisning av video",
	"videoLink": "{type} länk",
	"profile": "Profil | Profiler",
	"redirectToExternalSite": "Vidarekoppla till extern sida",
	"residentialRedirectUrlExample": "exempelvis https://...",
	"optional": "valfritt",
	"residentialRedirectUrlExplanation": "Använd detta fält om du vill skicka vidare besökare till en annan webbplats.",
	"createdAt": "Skapat den",
	"overview": "Översikt",
	"mediaTypeComboMessage": "Eller klicka på knappen för att välja bland andra mediatyper.",
	"mediaTypeDefaultMessage": "Välj media-typ via knappen",
	"userIsDeveloper": "Visa utvecklarverktyg",
	"noFloorplansSetup": "Inga planskisser har skapats",
	"mark": "Markera",
	"createResidentialTemplateBeforeShop": "För att kunna skapa tillvalsbutiken, måste du först ange/skapa bostadstyp",
	"noShopsetsSetup": "Inget produktpaket har skapats",
	"shopSet": "Produktpaket | Produktpaket",
	"productsThatArePartOfSet": "Produkter som finns i produktpaketet",
	"manualSelectAndSkip": "Välj själv (Hoppa över)",
	"event": "Händelse | Händelser",
	"url": "Url",
	"enabled": "Aktiverat",
	"webhook": "Webhook | Webhooks",
	"createWebhook": "Skapa webhook",
	"setting": "Inställning | Inställningar",
	"webhookSecret": "Secret",
	"attempt": "Försök",
	"of": "av",
	"log": "Logg | Logg",
	"noWebhooks": "Inga webhooks",
	"webhookIsSystem": "Systemintegrering",
	"noLogs": "Inga loggar",
	"token": "Token | Token",
	"createToken": "Skapa token",
	"noTokens": "Inga token",
	"acl": "Tillgångskontroll",
	"tokenSecretMessage": "Vänligen kopiera denna nyckel och spara den på ett säkert ställe. Av säkerhetsskäl visar vi den endast en gång.",
	"shopContractNoticeSellerApproachingSubject": "Köpares signeringsfrist löper ut",
	"shopContractNoticeSellerOverdueSubject": "Köpares signeringsfrist har löpt ut",
	"shopContractNoticeSellerExpires": "Köpare har inte genomfört signering av tillvalskontrakt.",
	"unit": "Enhet | Enheter",
	"noUnits": "",
	"noMoreUnits": "",
	"createUnit": "",
	"unitTemplate": "",
	"residentialSaleWithBroker": "",
	"residentialSaleSelf": "",
	"residentialSaleWithBrokerBidHelperText": "Här kan du lägga till en valfri länk via vilken du kan sälja objektet. Länken kommer att finnas \nunder ”köp”-knappen på bostad-sidan. ",
	"bidContractMissingShowBidUrl": "Köpbekräftelse saknas. Ladda upp köpbekräftelse för projektet för att se budgivningslänk. ",
	"residentialSaleBidHelperText": "Med denna länk kan du sälja objektet digitalt. \nLänken är tillgänglig under ”köp”-knappen på bostad-sidan, men länken kan även kopieras härifrån. \nKöp som genomförts via denna länk kommer att figurera i säljarens dashboard. ",
	"residentialSaleBidActiveHelperText": "Köpmodulen har aktiverats. Om du vill ta i bruk en annan försäljningsmetod kan du beställa systemintegration. \nKontakta oss via knappen nedan för mer information och beställning. ",
	"pressButtonBelowToGoToShopNoticeSeller": "Klicka på knappen för mer information om tillvalsbutiken",
	"copy": "Kopiera",
	"copied": "Kopierat",
	"paywallContentFieldUsedByFollowingModules ": "Dessa fält är inte relevanta för aktiverade moduler",
	"paywallContentPressButtonToReadMore": "Klicka på knappen för att läsa mer eller aktivera modulen | Klicka på en av knapparna för att läsa mer eller aktivera modulen",
	"deliveryLogClickLabel": "{resource} levererat ─ öppnat",
	"deliveryLogOpenLabel": "{resource} levererat ─ sett",
	"deliveryLogFailedLabel": "{resource} kunde inte levereras",
	"deliveryLogDeliveredLabel": "{resource} levererat ─ inte öppnat",
	"deliveryLogUnknownLabel": "{resource} ─ väntar på leverans",
	"invite": "Inbjudan",
	"info": "Info",
	"oneOrMoreDeadlinesExpired": "En eller flera frister har löpt ut",
	"projectShopsOpenByBuyer": "Öppnat av köpare",
	"contractStatusNotAccepted": "Väntar på godkännande",
	"noMoreShops": "Inga fler tillvalsbutiker",
	"projectShopsCreateResidentialsWarning": "Skapa bostäder innan du lägger upp tillvalsbutiker.",
	"projectShopsEditShop": "Inte skapat",
	"projectShopsCreateResidentials": "Skapa bostäder",
	"projectShopsNotSold": "Inte sålt",
	"projectSaleBrokerGuide1": "",
	"projectSaleBrokerGuide2": "",
	"projectSaleBrokerGuide3": "",
	"residentialsFlatfinderGuideInfo": "",
	"shop": "Tillvalsbutik | Tillvalsbutiker",
	"devtools": "",
	"embed": "",
	"imageRecommendation": "",
	"height": "",
	"width": "",
	"imageFlatfinderRecommendation": "",
	"consentRequiredTitle": "",
	"consentRequiredDescription": "",
	"consentStatisticsTitle": "",
	"consentStatisticsDescription": "",
	"consentMarketingTitle": "",
	"consentMarketingDescription": "",
	"consentDialogTitle": "",
	"consentDialogDescription": "",
	"consentDialogLabelAccept": "",
	"consentDialogLabelAcceptAll": "",
	"consentDialogLabelConfirm": "",
	"consentDialogLabelConfigure": "",
	"editConsents": "",
	"noAttachments": "",
	"tourVideoUpload": "",
	"language": "Språk",
	"textOverwriteWarning": "",
	"cookieVideoBlockMessage": "",
	"sizes": "",
	"productLink": "",
	"showDefaultSelection": "",
	"noUpgradesSelected": "",
	"setValue": "",
	"internalName": "",
	"useInternalName": "",
	"shopWelcomeText": "",
	"scopedProduct": "",
	"contactImport": "",
	"importTemplateDescription": "",
	"socialMedia": "",
	"downloadTemplate": "",
	"projectCreationVideoMessage": "",
	"singleResidential": "",
	"myPage": "",
	"contractStatusNotAcceptedBySeller": "",
	"contractStatusAcceptedBySeller": "",
	"followSoMeTitle": "",
	"projectAdditionalFieldsSoMeDescriptionArea": "",
	"projectAdditionalFieldsSeoDescriptionArea": "",
	"projectAdditionalFieldsFacebookAdsDescriptionArea": "",
	"SEO": "",
	"facebookAds": "",
	"back": "Tillbaka",
	"newFolder": "",
	"dragFolderOrDrop": "",
	"managementOperationMaintenance": "",
	"residentialAccount": "",
	"clickToFilter": "",
	"imagesOnlyAsIllustration": "",
	"foundXResults": "",
	"active": "",
	"inactive": "",
	"contract": "",
	"buyConfirmation": "",
	"residentialAccountLogin": "",
	"loginPressButton": "",
	"loginVisitLink": "",
	"loginLinkExpires": "",
	"loginGetNewLink": "",
	"residentialAccountYourUnits": "",
	"residentialAccountLoginTooSeeUnits": "",
	"loginEnterValue": "",
	"loginSuccessMessage": "",
	"sortOn": "",
	"highLow": "",
	"lowHigh": "",
	"syncUser": "",
	"lowHighLetter": "",
	"highLowLetter": "",
	"addFilter": "",
	"resetFilter": "",
	"validTo": "",
	"registrationComplete": "",
	"clickToLogin": "",
	"welcomeToKvass": "",
	"enterPasswordAndClickComplete": "",
	"tokenExpiredRequestNew": "",
	"author": "",
	"post": "",
	"createPost": "",
	"shopShort": "",
	"developer": "",
	"access": "",
	"supplier": "",
	"technicalDescription": "",
	"addTechnicalDescription": "",
	"switchTenant": "",
	"leadMessageSent": "",
	"externalUserAlertAdmin": "",
	"externalUserAlertNoRights": "",
	"externalUserAlertRoles": "",
	"externalUserAlertAccess": "",
	"shopPresentationPreviewAlert": "",
	"addon:project:complaint": "",
	"archived": "",
	"showArchived": "",
	"archive": "",
	"integrationCompliance": "",
	"documentSigned": "",
	"documentSignedConfirmationMessage": "",
	"integrationNotificationEmailBody": "",
	"integrationNotificationEmailBodyReminder": "",
	"integrationNotificationEmailSubject": "",
	"noContracts": "",
	"awaitingSignature": "",
	"residentialAddressControl": "",
	"residentialAccountNotSold": "",
	"createContract": "",
	"lastUpdated": "",
	"complaint": "",
	"lookingForAContactAdd": "",
	"orderConfirmationCondition": "",
	"orderConfirmationConditionShort": "",
	"orderConfirmation": "",
	"unitLimitMessage": "",
	"taskStatusUndone": "",
	"taskStatusDone": "",
	"taskStatusRejected": "",
	"taskStatusRequest": "",
	"subjectArea": "",
	"showCompleted": "",
	"complaintRequest": "",
	"complaintTermsOfRequest": "",
	"complaintAddSelection": "",
	"complaintRegister": "",
	"companyOrder": "",
	"activatedBy": "",
	"orderConfirmationAttached": "",
	"install": "",
	"uninstall": "",
	"installed": "",
	"residentialAccountPublishAlert": "",
	"reset": "",
	"showLess": "",
	"residentialAccountAccess": "",
	"taskChangeStatus": "",
	"taskChangeStatusSubject": "",
	"residentialAccountSettingsShowShop": "",
	"residentialAccountSettingsShowShopSublabel": "",
	"residentialAccountSettingsShowShopDocuments": "",
	"residentialAccountSettingsShowShopDocumentsSublabel": "",
	"residentialAccountSettingsShowResidentialDocuments": "",
	"residentialAccountSettingsShowResidentialDocumentsSublabel": "",
	"redirectUrl": "",
	"contactSeller": "",
	"importFromCatalog": "",
	"reject": "",
	"rejected": "",
	"addon:project:flatfinder": "",
	"blockComplaints": "",
	"unblockComplaints": "",
	"complaintsBlocked": "",
	"taskStatusChangeToUndone": "",
	"taskStatusChangeToDone": "",
	"taskStatusChangeToRejected": "",
	"attachmentsSigningUploadWarning": "",
	"addonIsIncludedIn": "",
	"tourHowToEmbedFlatfinder": "",
	"helpers:roleManager": "",
	"helpers:roleSigningAuthority": "",
	"helpers:roleSalesman": "",
	"helpers:roleShopManager": "",
	"helpers:roleShopSigningAuthority": "",
	"helpers:roleShopSalesman": "",
	"salesmanShopVisibility": "",
	"productBundleContains": "",
	"productBundle": "",
	"rent": "",
	"saleType": "",
	"actionLabel:export": "",
	"actionLabel:integration": "",
	"action": "",
	"helpers:productBundle": "",
	"office": "",
	"integrationDpaRecommendation": "",
	"ordinaryProductPrice": "",
	"helpers:shopPricingMethodTitle:add": "",
	"helpers:shopPricingMethodDescription:add": "",
	"helpers:shopPricingMethodTitle:diff": "",
	"helpers:shopPricingMethodDescription:diff": "",
	"selectMethod": "",
	"priceCalculation": "",
	"unitNumber": "",
	"productUpdateAffectedProducts": "",
	"upcomingLabel": "",
	"hide": "",
	"residentialAdditionalFieldsLabel": "",
	"link": "",
	"loggingIn": "",
	"setDueDates": "",
	"complaintDescription": "",
	"helpers:managementOperationMaintenance": "",
	"helpers:roleComplaintManager": "",
	"residentialAccountLoginCondition": "",
	"noComplaints": "",
	"complaintDisclaimerConsent": "",
	"complaintDisclaimerEdit": "",
	"fdvControlLabel": "",
	"fdvControlSublabel": "",
	"shopEditDueAtUnitNotSold": "",
	"confirmed": "",
	"control": "",
	"noMembers": "",
	"complaintRoleMissing": "",
	"folder": "",
	"helpers:complaintsList": "",
	"completeTask": "",
	"changeStatus": "",
	"confirmRequest": "",
	"rejectRequest": "",
	"setTaskToUndone": "",
	"cannotEditMultipleResidentialFieldsSameSaleType": "",
	"noContacts": "",
	"filterEmptyState": "",
	"invoice": "",
	"receiver": "",
	"addon:project:presentation:sublabel": "",
	"addon:project:bid:sublabel": "",
	"addon:project:purchase:sublabel": "",
	"addon:project:shop:sublabel": "",
	"addon:project:shop-purchase:sublabel": "",
	"addon:project:residential-account:sublabel": "",
	"addon:project:residential-account": "",
	"billing": "",
	"featuredResidentialPropertyHelper": "",
	"viewProject": "",
	"unitSelectorIsEntry": "",
	"autocompleteAddressAlert": "",
	"externalFileDisclaimer": "",
	"getMarkerPosition": "",
	"createResource": "",
	"taskCommentsReceiverInfo": "",
	"createdBy": "",
	"openhouseEmptyState": "",
	"documentsEmptyState": "",
	"startShops": "",
	"noUnitTemplates": "",
	"unitTemplatesEmptyState": "",
	"noApplicantShopContractTooltip": "",
	"noUsers": "",
	"usersEmptyState": "",
	"cannotCreateMoreX": "",
	"cannotDelete": "",
	"noX": "",
	"thisFieldIsFetchedFromX": "",
	"addSecondaryColor": "",
	"primaryColor": "",
	"secondaryColor": "",
	"unitMustBeSoldToSetOwner": "",
	"owner": "",
	"tableFooterTip": "",
	"sendMessage": "",
	"mediaDescriptionPlaceholder": "",
	"taskTypeDefault": "",
	"taskTypeComplaint": "",
	"downloadX": "",
	"noUnsavedChanges": "",
	"installMore": "",
	"propertyType:four-person-home": "",
	"addCompanyInfo": "",
	"shopTotalLabel": "Totalpris för tillval",
	"addDesiredAmount": "",
	"creationDate": "",
	"shop-partialConfirmationMessage": "Vi har mottagit din signerade tillvalsbeställning. När motparten har signerat denna kommer du att få en bekräftelse. Bifogat hittar du en översikt över din beställning.",
	"shop-partialAcceptMessage": "Ditt tillvalskontrakt har godkänts",
	"shop-partialDeclinedMessage": "Ditt tillvalskontrakt har inte godkänts",
	"customConfirmationMessage": "Vi har mottagit ditt kontrakt och du kommer att få svar från oss så snart som möjligt",
	"customAcceptMessage": "Ditt kontrakt har godkänts",
	"customDeclinedMessage": "Ditt kontrakt har inte godkänts"
}